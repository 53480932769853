import { createStore } from 'vuex'
import { useToast, POSITION } from 'vue-toastification'

import axios from 'axios'
import router from '../router/index';

const toast = useToast();

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

const createCors = {
  withCredentials: true,
  headers: {
    'Authorization': `Bearer ${process.env.VUE_APP_API_SECRET}`,
    'Content-Type': 'application/json',
  },
};

export default createStore({
  state: {
    getAkun: '',

    dataOrder: [],
    dataOrderIncoming: 0,
    dataOrderActive: 0,
    dataOrderOmset: 0,

    dataTopViews: [],

    orderDetail: [],
    dataGallery: [],

    dataPartnership: [],
    detailPartnership: [],
    intrnPartnership: [],

    saldoPartnership: [],
    saldoTrnPartnership: [],

    dataCatalogue: [],
    detailCatalogue: [],

    dataAccount: [],
  },
  getters: {
  },
  mutations: {
    getAkun(state, getAkun){
      state.getAkun = getAkun
    },

    dataOrder(state, dataOrder){
      state.dataOrder = dataOrder
    },
    dataOrderIncoming(state, dataOrderIncoming){
      state.dataOrderIncoming = dataOrderIncoming
    },
    dataOrderActive(state, dataOrderActive){
      state.dataOrderActive = dataOrderActive
    },
    dataTopViews(state, dataTopViews){
      state.dataTopViews = dataTopViews
    },

    dataOrderOmset(state, dataOrderOmset){
      state.dataOrderOmset = dataOrderOmset
    },

    orderDetail(state, orderDetail){
      state.orderDetail = orderDetail
    },
    dataGallery(state, dataGallery){
      state.dataGallery = dataGallery
    },

    dataPartnership(state, dataPartnership){
      state.dataPartnership = dataPartnership
    },
    detailPartnership(state, detailPartnership){
      state.detailPartnership = detailPartnership
    },
    intrnPartnership(state, intrnPartnership){
      state.intrnPartnership = intrnPartnership
    },

    saldoPartnership(state, saldoPartnership){
        state.saldoPartnership = saldoPartnership
    },
    saldoTrnPartnership(state, saldoTrnPartnership){
        state.saldoTrnPartnership = saldoTrnPartnership
    },

    dataCatalogue(state, dataCatalogue){
      state.dataCatalogue = dataCatalogue
    },
    detailCatalogue(state, detailCatalogue){
      state.detailCatalogue = detailCatalogue
    },

    dataAccount(state, dataAccount){
      state.dataAccount = dataAccount
    }
  },
  actions: {
    async loginAkun(res, req){
       await api
          .post("/loginAkun", {
              username: req.username,
              password: req.password
          }, createCors)
          .then((response) => {
              let dataAkun = response.data;
              toast.success(dataAkun.message, { position: POSITION.BOTTOM_LEFT });
              
              router.push('/order');
          })
          .catch((error) => {
              let errorMessage = error.response.data;
              toast.error(errorMessage.message);
          });
    },
    async getAkun({ commit }){
      await api
         .get("/getAkun", createCors)
         .then((response) => {
             let getAkun = response.data.getAkun;
             commit('getAkun', getAkun);
         })
   },
    async logoutAkun(){
       await api
        .get("/logoutAkun", createCors)
        .then(() => {
            router.push('/');
        })
        .catch((error) => console.log(error));
    },
    async dataOrder({commit}){
      await api
        .get("/dataOrder", createCors)
        .then((response) => {
          let dataOrder = response.data.dataOrder;

          commit('dataOrder', dataOrder);
        })
    },
    async dataOrderIncoming({commit}){
      await api
        .get("/dataOrderIncoming", createCors)
        .then((response) => {
          let dataOrderIncoming = response.data.dataOrderIncoming;

          commit('dataOrderIncoming', dataOrderIncoming);
        })
    },
    async dataOrderActive({commit}){
      await api
        .get("/dataOrderActive", createCors)
        .then((response) => {
          let dataOrderActive = response.data.dataOrderActive;

          commit('dataOrderActive', dataOrderActive);
        })
    },
    async dataOrderOmset({commit}){
      await api
        .get("/dataOrderOmset", createCors)
        .then((response) => {
          let dataOrderOmset = response.data.dataOrderOmset;

          commit('dataOrderOmset', dataOrderOmset);
        })
    },
    async dataTopViews({commit}){
      await api
        .get("/dataTopViews", createCors)
        .then((response) => {
          let dataTopViews = response.data.dataTopViews;

          commit('dataTopViews', dataTopViews);
        })
    },
    // ORDER DETAIL
    async orderDetail({commit}, req){
      await api
        .get(`/orderDetail/${req.id_invite}`, createCors)
        .then((response) => {
          let orderDetail = response.data.orderDetail;

          commit('orderDetail', orderDetail);
        })
    },
    async dataGallery({commit}, req){
      await api
        .get(`/dataGallery/${req.id_invite}`, createCors)
        .then((response) => {
          let dataGallery = response.data.dataGallery;

          commit('dataGallery', dataGallery);
        })
    },
    async goPublish(res, req){
      await api
        .postForm("/goPublish", {
          id_invite: req.id_invite,
          fileBayar: req.fileBayar
        }, createCors)
        .then(() => {
          toast.success('Yey! Undangan berhasil di publish');
        })
        .catch(() => {
          toast.error('Oops, Undangan gagal di publish');
        });
    },
    async goOff(res, req){
      await api
        .post("/goOff", {
          id_invite: req.id_invite
        }, createCors)
        .then(() => {
          toast.success('Yey! Undangan berhasil di nonaktifkan');
          // router.push('/order');
        })
        .catch(() => {
          toast.error('Oops, Undangan gagal di nonaktifkan');
        });
    },
    async deleteInvite(res, req){
      await api
        .postForm("/deleteInvite", {
          id_invite: req.id_invite,
          noinvoice: req.noinvoice,
          url_params: req.url_params
        }, createCors)
        .then(() => {
          toast.success('Yey! Undangan berhasil di hapus');
        })
        .catch(() => {
          toast.error('Oops, Undangan gagal di hapus');
        });
    },
    async downloadFotoCouple(res, req){
      await api
        .post("/downloadFotoCouple", {
          url_type: req.url_type,
          foto_couple: req.foto_couple
        }, {responseType: 'blob'}, createCors)
        .then((response) => {
          let blob = new Blob([response.data], { type: 'image/jpeg' }),
          url = window.URL.createObjectURL(blob)

          window.open(url)
        })
        .catch(() => {
          toast.error('Oops, gagal unduh file');
        });
    },
    async downloadFotoGallery(res, req){
      await api
        .post("/downloadFotoGallery", {
          url_type: req.url_type,
          foto_gallery: req.foto_gallery
        }, {responseType: 'blob'}, createCors)
        .then((response) => {
          let blob = new Blob([response.data], { type: 'image/jpeg' }),
          url = window.URL.createObjectURL(blob)

          window.open(url)
        })
        .catch(() => {
          toast.error('Oops, gagal unduh file');
        });
    },
    async downloadFotoPria(res, req){
      await api
        .post("/downloadFotoPria", {
          url_type: req.url_type,
          foto_pria: req.foto_pria
        }, {responseType: 'blob'}, createCors)
        .then((response) => {
          let blob = new Blob([response.data], { type: 'image/jpeg' }),
          url = window.URL.createObjectURL(blob)

          window.open(url)
        })
        .catch(() => {
          toast.error('Oops, gagal unduh file');
        });
    },
    async downloadFotoWanita(res, req){
      await api
        .post("/downloadFotoWanita", {
          url_type: req.url_type,
          foto_wanita: req.foto_wanita
        }, {responseType: 'blob'}, createCors)
        .then((response) => {
          let blob = new Blob([response.data], { type: 'image/jpeg' }),
          url = window.URL.createObjectURL(blob)

          window.open(url)
        })
        .catch(() => {
          toast.error('Oops, gagal unduh file');
        });
    },
    async downloadMusic(res, req){
      await api
        .post("/downloadMusic", {
          url_type: req.url_type,
          music: req.music
        }, {responseType: 'blob'}, createCors)
        .then((response) => {
          let blob = new Blob([response.data], { type: 'audio/mpeg3' }),
          url = window.URL.createObjectURL(blob)

          window.open(url)
        })
        .catch(() => {
          toast.error('Oops, gagal unduh file');
        });
    },
    // ORDER UPDATE
    async orderUpdate(res, req){
      await api
        .postForm(`/orderUpdate/${req.id_invite}`, {
            url_type: req.url_type,

            man_name: req.man_name,
            man_call: req.man_call,
            man_father: req.man_father,
            man_mother: req.man_mother,
            man_child_of: req.man_child_of,

            women_name: req.women_name,
            women_call: req.women_call,
            women_father: req.women_father,
            women_mother: req.women_mother,
            women_child_of: req.women_child_of,

            akad_date: req.akad_date,
            akad_start: req.akad_start,
            akad_end: req.akad_end,
            akad_timezone: req.akad_timezone,
            akad_address: req.akad_address,
            url_gmaps_akad: req.url_gmaps_akad,
            
            resepsi_date: req.resepsi_date,
            resepsi_start: req.resepsi_start,
            resepsi_end: req.resepsi_end,
            resepsi_timezone: req.resepsi_timezone,
            resepsi_address: req.resepsi_address,
            url_gmaps_resepsi: req.url_gmaps_resepsi,

            iframe_gmaps: req.iframe_gmaps,

            bank_one: req.bank_one,
            an_one: req.an_one,
            norek_one: req.norek_one,
            bank_two: req.bank_two,
            an_two: req.an_two,
            norek_two: req.norek_two,
            nama_penerima: req.nama_penerima,
            alamat_penerima: req.alamat_penerima,

            nameFileFotoCouple: req.nameFileFotoCouple,
            nameFileFotoMan: req.nameFileFotoMan,
            nameFileFotoWomen: req.nameFileFotoWomen,
            nameFileSong: req.nameFileSong,

            fileFotoCouple: req.fileFotoCouple,
            fileFotoMan: req.fileFotoMan,
            fileFotoWomen: req.fileFotoWomen,
            fileSong: req.fileSong,

            noInvoice: req.noInvoice,
            fileFotoGallery: req.fileFotoGallery,
        }, createCors)
        .then(() => {
          // toast.success('Yey! Undangan berhasil di update');
        })
        .catch(() => {
          // toast.error('Oops, Undangan gagal di update');
        })
    },
    // CATALOGUE
    async dataCatalogue({commit}){
      await api
        .get("/dataCatalogue", createCors)
        .then((response) => {
          let dataCatalogue = response.data.dataCatalogue;

          commit('dataCatalogue', dataCatalogue);
        })
    },
    async detailCatalogue({commit}, req){
      await api
        .get(`/detailCatalogue/${req.uuid_catalogue}`, createCors)
        .then((response) => {
          let detailCatalogue = response.data.detailCatalogue;

          commit('detailCatalogue', detailCatalogue);
        })
    },
    async catalogueAdd(res, req){
      await api
        .postForm("/catalogueAdd", {
          uuid: req.uuid,
          namaCatalogue: req.namaCatalogue,
          urlParams: req.urlParams,
          category: req.category,
          badge: req.badge,
          badgeColor: req.badgeColor,
          price: req.price,
          disc: req.disc,
          grandTotal: req.grandTotal,
          thumbCatalogue: req.thumbCatalogue,
        }, createCors)
        .then(() => {
          router.push('/catalogue');
          toast.success('Yey! berhasil membuat katalog baru');
        })
        .catch(() => {
          toast.error('Oops, gagal menambahkan katalog');
        });
    },
    async catalogueUpdate(res, req){
      await api
        .postForm(`/catalogueUpdate/${req.uuid}`, {
          namaCatalogue: req.namaCatalogue,
          urlParams: req.urlParams,
          category: req.category,
          used: req.used,
          badge: req.badge,
          badgeColor: req.badgeColor,
          price: req.price,
          disc: req.disc,
          grandTotal: req.grandTotal,
          nameThumbCatalogue: req.nameThumbCatalogue,
          thumbCatalogue: req.thumbCatalogue,
        }, createCors)
        .then(() => {
          router.push('/catalogue');
          toast.success('Yey! Katalog berhasil di update');
        })
        .catch(() => {
          toast.error('Oops, Katalog gagal di update');
        })
    },
    async catalogueDelete(res, req){
      await api
        .get(`/catalogueDelete/${req.uuidCatalogue}`, createCors)
        .then(() => {
          router.push('/catalogue');
          toast.success('Yey! Katalog berhasil di hapus');
        })
        .catch(() => {
          toast.error('Yey! Katalog berhasil di hapus');
        })
    },
    // PARTNERSHIP
    async checkKodeVoucher(res, req){
      await api
        .get(`/checkKodeVoucher/${req.kodeVoucher}`, createCors)
        .then((response) => {
          let checkKodeVoucher = response.data.checkKodeVoucher;
          if(checkKodeVoucher == 1){
            toast.error('Maaf, Kode Voucher Sudah Dipakai');
            document.getElementById('addPartner').disabled = true
          }else{
            toast.success('Kode Voucher berhasil di gunakan!');
            document.getElementById('addPartner').disabled = false
          }
        })
    },
    async partnershipAdd(res, req){
      await api
        .postForm("/partnershipAdd", {
          uuid: req.uuid,
          namaPemilik: req.namaPemilik,
          noHp: req.noHp,
          namaBisnis: req.namaBisnis,
          descBisnis: req.descBisnis,
          noRek: req.noRek,
          namaInstagram: req.namaInstagram,
          linkInstagram: req.linkInstagram,
          kategori: req.kategori,
          isJob: req.isJob,

          logoBisnis: req.logoBisnis,

          kodeVoucher: req.kodeVoucher,
          passwordAkun: req.passwordAkun,
          discVoucher: req.discVoucher
        }, createCors)
        .then(() => {
          router.push('/partnership');
          toast.success('Yey! berhasil membuat partnership');
        })
        .catch(() => {
          toast.error('Oops, gagal menambahkan partnership');
        });
    },
    async dataPartnership({commit}){
      await api
        .get("/dataPartnership", createCors)
        .then((response) => {
          let dataPartnership = response.data.dataPartnership;

          commit('dataPartnership', dataPartnership);
        })
    },
    async detailPartnership({commit}, req){
      await api
        .get(`/detailPartnership/${req.uuid_partnership}`, createCors)
        .then((response) => {
          let detailPartnership = response.data.detailPartnership;

          commit('detailPartnership', detailPartnership);
        })
    },
    async intrnPartnership({commit}, req){
      await api
        .get(`/intrnPartnership/${req.uuid_partnership}`, createCors)
        .then((response) => {
          let intrnPartnership = response.data.intrnPartnership;

          commit('intrnPartnership', intrnPartnership);
        })
    },
    async saldoPartnership({commit}, req){
        await api
          .get(`/saldoPartnership/${req.uuid_partnership}`, createCors)
          .then((response) => {
            let saldoPartnership = response.data.saldoPartnership;
  
            commit('saldoPartnership', saldoPartnership);
          })
    },
    async saldoTrnPartnership({commit}, req){
        await api
          .get(`/saldoTrnPartnership/${req.uuid_partnership}`, createCors)
          .then((response) => {
            let saldoTrnPartnership = response.data.saldoTrnPartnership;
  
            commit('saldoTrnPartnership', saldoTrnPartnership);
          })
    },
    async isValidSaldoTrn(res, req){
      await api
        .post("/isValidSaldoTrn", {
          idTrnSaldo: req.idTrnSaldo,
          uuid_partnership: req.uuid_partnership,
          jmlTarik: req.jmlTarik,
          saldoAkhir: req.saldoAkhir,
        }, createCors)
        .then(() => {
          toast.success('Yey! transfer saldo berhasil dilakukan');
          location.reload();
        })
        .catch(() => {
          toast.error('Oops, gagal transfer saldo');
        });
    },
    async accountAdd(res, req){
      await api
        .post("/accountAdd", {
          uuid: req.uuid,
          nama: req.nama,
          jabatan: req.jabatan,
          username: req.username,
          password: req.password,
        }, createCors)
        .then(() => {
          router.push('/account');
          toast.success('Yey! berhasil membuat akun baru');
        })
        .catch(() => {
          toast.error('Oops, gagal menambahkan akun baru');
        });
    },
    async deleteAccount(res, req){
      await api
        .get(`/deleteAccount/${req.id_akun}`, createCors)
        .then(() => {
          toast.success('Yey! Akun berhasil di hapus');
        })
        .catch(() => {
          toast.error('Yey! Akun berhasil di hapus');
        })
    },
    async dataAccount({commit}){
      await api
        .get("/dataAccount", createCors)
        .then((response) => {
          let dataAccount = response.data.dataAccount;

          commit('dataAccount', dataAccount);
        })
    }
  },
  modules: {
  }
})
