import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/order',
    name: 'OrderView',
    component: () => import('../views/OrderView.vue')
  },
  {
    path: '/order-detail/:id_invite',
    name: 'OrderDetailView',
    component: () => import('../views/OrderDetailView.vue')
  },
  {
    path: '/master',
    name: 'MasterView',
    component: () => import('../views/MasterView.vue')
  },
  {
    path: '/catalogue',
    name: 'CatalogueView',
    component: () => import('../views/mCatalogue/CatalogueView.vue')
  },
  {
    path: '/catalogue/add',
    name: 'CatalogueAdd',
    component: () => import('../views/mCatalogue/CatalogueAdd.vue')
  },
  {
    path: '/catalogue/detail/:uuid_catalogue',
    name: 'CatalogueDetail',
    component: () => import('../views/mCatalogue/CatalogueDetail.vue')
  },
  {
    path: '/partnership',
    name: 'PartnershipView',
    component: () => import('../views/mPartnership/PartnershipView.vue')
  },
  {
    path: '/partnership/add',
    name: 'PartnershipAdd',
    component: () => import('../views/mPartnership/PartnershipAdd.vue')
  },
  {
    path: '/partnership/detail/:uuid_partnership',
    name: 'PartnershipDetail',
    component: () => import('../views/mPartnership/PartnershipDetail.vue')
  },
  {
    path: '/account',
    name: 'AccountView',
    component: () => import('../views/mAccount/AccountView.vue')
  },
  {
    path: '/account/add',
    name: 'AccountAdd',
    component: () => import('../views/mAccount/AccountAdd.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
